












































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import VueUeditorWrap from 'vue-ueditor-wrap';
import { BonusService } from '@/services/bonus-service';


@Component({
  components: {
    VueUeditorWrap,
  }
})
export default class addProductMap extends Vue {
  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/wecom-bsp-backend/admin/upload/content',
    // serverUrl: '/api/application/img/upload/imgUpload',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/ueditor/',
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
      ///*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map','link', 'unlink'
      'rowspacingtop', 'rowspacingbottom', 'lineheight'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public labelPosition: String = 'top';
  public form: any = {};
  public type: any = ''; //判断是修改还是添加，-1为新增
  public loading: boolean = false;
  private awardsAdminIdList: any[] = []; //项目下拉框数据
  private statusOptions: any[] = [
    { label: '草稿', value: 0 },
    { label: '生效', value: 1 }
  ];
  private showTypeOptions: any[] = [
    { label: '奖项', value: '0' },
    { label: '备注', value: '1' }
  ];
  public rules: any = {
    showType: [
      { required: true, message: '请选择类型', trigger: ['blur', 'change'] }],
    franchise: [
      { required: true, message: '请输入 Franchise', trigger: 'blur' }
    ],
    title: [
      { required: true, message: '请输入内容标题', trigger: 'blur' }
    ],
    orderBy: [
      { required: true, message: '请输入正整数', trigger: 'blur' }
    ],
    awardsAdminId: [
      { required: true, message: '请选择关联项目', trigger: ['blur', 'change'] }
    ],
    content: [
      { required: true, message: '请输入内容', trigger: 'blur' }
    ]
  };
  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    this.form = {
      showType: '',
      franchise: this.$route.query.franchise || '',
      title: '',
      orderBy: '',
      awardsAdminId: '',
      content: '',
    };
    this.type = JSON.parse(localStorage.getItem('addDetailsNewContent') || '');
    console.log(this.type)
    this.type != '-1' && await this.fetchDetail();
  }
  //初始化关联项目
  private async initAwardsAdminIdList() {
    const param = {
      franchise: this.type.franchise,
      showType: this.type.showType
    };
    const res = await this.bonusService.awardsOptions(param);
    this.awardsAdminIdList = res || [];
  }
  //根据类型和franchise改变关联项目
  public async getAwardsAdminIdList() {
    this.form.awardsAdminId = '';
    const param = {
      franchise: this.form.franchise,
      showType: this.form.showType
    };
    const res = await this.bonusService.awardsOptions(param);
    this.awardsAdminIdList = res || [];
  }
  //获取详情
  public async fetchDetail() {
    this.loading = true;
    const list = this.getListFromStorage('newContentList');
    const res = list.filter(item => item.id === this.type.id)[0];
    await this.initAwardsAdminIdList();
    this.form = res;
    this.loading = false;
  }
  //value与label的转换
  private getDisplayValues() {
    const showTypeDisplay = this.showTypeOptions.find(item => item.value === this.form.showType).label || '';
    const awardsAdminDisplay = this.awardsAdminIdList.find(item => item.key === this.form.awardsAdminId).value || '';
    return { showTypeDisplay, awardsAdminDisplay };
  }
  //从缓存中获取列表
  private getListFromStorage(key: string): any[] {
    return JSON.parse(localStorage.getItem(key) || '[]');
  }
  //将列表存入缓存
  private saveListToStorage(key: string, list: any[]): void {
    localStorage.setItem(key, JSON.stringify(list));
  }
  //保存
  public submitForm(formName: string): void {
    const addRef = (this.$refs.form as any);
    addRef.validate(async valid => {
      if (valid) {
        const { showTypeDisplay, awardsAdminDisplay } = await this.getDisplayValues();

        const list = this.getListFromStorage('newContentList');
        const itemToSave = { ...this.form, showTypeDisplay, awardsAdminDisplay };

        if (!this.form.id) {
          const id = new Date().getTime();
          list.unshift({...itemToSave, id});
          this.$message.success('添加成功');
        } else {
          const index = list.findIndex(item => item.id === this.form.id);
          if (index !== -1) {
            list[index] = itemToSave;
            this.$message.success('修改成功');
          }
        }

        this.saveListToStorage('newContentList', list);
        this.$router.push('/detailsNewContent');
      } else {
        console.error('error submit!!');
      }
    });
  }
  //取消
  public async handleGoBack(): Promise<void> {
    await this.$confirm('当前信息将会丢失，确认是否取消?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });
    this.$router.push('/detailsNewContent');
  }
}
